function backingScale(context) {
    if ('devicePixelRatio' in window) {
        if (window.devicePixelRatio > 1) {
            return window.devicePixelRatio;
        }
    }
    return 1;
}

window.addEventListener('load', function () {

    // Smoke OFF
    return;

    let canvases = document.querySelectorAll('.js-smoke');
    for(let canvas of canvases) {
        smokeAnimate(canvas);
    }

    function smokeAnimate(canvas) {
        let can = document.querySelector('.js-smoke');

        canvasWidth = canvas.width = can.offsetWidth;
        canvasHeight = canvas.height = can.offsetHeight;

        let ctx = can.getContext("2d");
        var scaleFactor = backingScale(ctx);
 
        if (scaleFactor > 1) {
            can.width = can.width * scaleFactor;
            can.height = can.height * scaleFactor;
            // update the context for the new canvas scale
            ctx = can.getContext("2d");
        }

       // console.log('Scale: ' + scaleFactor);


        var img1 = new Image();
        img1.src = 'src/img/super-1.png';

        var img2 = new Image();
        img2.src = 'src/img/super-2.png';

        var img3 = new Image();
        img3.src = 'src/img/super-3.png';


        let mobile = false;
        if(window.innerWidth < 768) {
            mobile = true;
        }


        pCount = 0;
        pCollection = new Array();

        let puffs = 100;

        if(mobile) {
            puffs = 10;
        }


        function start() {
            for (let i1 = 0; i1 < puffs; i1++) {
                addNewParticle();
            }
            draw(new Date().getTime(), 0)
        }
        start();



        function addNewParticle() {

            let p = {};

            switch (Math.round(randBetween(0, 3))) {
                case 1:
                    p.smokeImage = img1;
                    break;
                case 2:
                    p.smokeImage = img2;
                    break;
                default:
                    p.smokeImage = img3;
            }

            // Старт частиц 
            p.top = randBetween(-1*canvasHeight*scaleFactor, canvasHeight*scaleFactor);
            p.left = randBetween(-1*canvasWidth*scaleFactor, canvasWidth*scaleFactor);

            p.start = new Date().getTime();
            p.life = randBetween(2000, 5000);
            p.speedUp = randBetween(-40, 40);

            p.speedRight = randBetween(-40, 40);

            p.rot = randBetween(-10, 10);
            p.red = Math.floor(randBetween(0, 255));
            p.blue = Math.floor(randBetween(0, 255));
            p.green = Math.floor(randBetween(0, 255));

            p.grow = true;
            p.startOpacity = 0;
            p.maxOpacity = 1;
            p.opacity = 0;
            p.newTop = p.top;
            p.newLeft = p.left;
            p.size = randBetween(300*scaleFactor, 900*scaleFactor);
            p.growth = 0;

            pCollection[pCollection.length] = p;
        }

        function draw(startT, totalT) {
            //Timing
            let timeDelta = new Date().getTime() - startT;
            let stillAlive = false;

            //Grab and clear the canvas
            let c = canvas;
           
            ctx.clearRect(0, 0, (c.width * scaleFactor), (c.height * scaleFactor));
        

            //Loop through particles
            for (let i = 0; i < pCollection.length; i++) {
                //Grab the particle
                let p = pCollection[i];

                if(!p) {
                    continue;
                }

                //Timing
                let td = new Date().getTime() - p.start;
                let frac = td / p.life

                if(frac <= 0) {
                    frac = 0;
                }

                //attributes that change over time
                let newTop = p.top - (p.speedUp * (td / 5000));
                let newLeft = p.left + (p.speedRight * (td / 5000));

                // Расчёт прозрачности
                if(frac > 0.5) {
                    p.opacity = 1 - frac;
                } else {
                    p.opacity =  frac;
                }

                if(p.opacity < 0) {
                    p.opacity = 0;
                }

                let newSize = p.size / 100;
                p.newTop = newTop;
                p.newLeft = newLeft;

                //Draw!
                ctx.globalAlpha = p.opacity;
                p.rot = p.rot + 0.001;
                drawImage(ctx, p.smokeImage, newLeft, newTop, p.rot, newSize);
            }

            // Чистка
            let removed = 0;
            for (let i = 0; i < pCollection.length; i++) {
                let p = pCollection[i];
                let td = new Date().getTime() - p.start;
                let frac = td / p.life;

                if(frac >= 1) {
                    //delete pCollection[i];
                    pCollection.splice(i, 1);
                    removed++;

                    //console.log(i);
                }
            }

            //console.log(pCollection);

            // Добавили столько сколько очистили
            for(let i = 0; i < removed; i++) {
                addNewParticle();
            }


            //Repeat if there's still a living particle
            requestAnimationFrame(function () {
                draw(startT, totalT);
            });
          
        }

        function randBetween(n1, n2) {
            let r = (Math.random() * (n2 - n1)) + n1;
            return r;
        }

        function randOffset(n, letiance) {
            //e.g. letiance could be 0.1 to go between 0.9 and 1.1
            let max = 1 + letiance;
            let min = 1 - letiance;
            let r = Math.random() * (max - min) + min;
            return n * r;
        }

        function drawImage(ctx, img, x, y, angle = 0, scale = 1) {
            ctx.save();
            ctx.translate(x + img.width * scale / 2, y + img.height * scale / 2);
            ctx.rotate(angle);
            ctx.translate(- x - img.width * scale / 2, - y - img.height * scale / 2);
            ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
            ctx.restore();
        }

        function clog(s) {
            console.log(s);
        }
    }

});