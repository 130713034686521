// Include scss
import "../scss/index.scss";

// Images
import "./images.js";

import "./smoke.js";

import "./hystmodal.min.js";

import "./metrika.js";

/* 
    Libs 
*/
import { Application, Assets, Container, Sprite, DisplacementFilter, ColorMatrixFilter, AlphaFilter, WRAP_MODES} from 'pixi.js';



// Lazyload



document.addEventListener('DOMContentLoaded', ()=>{
    console.warn('Created with love by Georgiy Antipov (a@topm.ru)');

    (async () =>
        {

            // Выравнивание
            function updateVhVw() {
                let one_vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) / 100;
                let one_vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) / 100;

                document.documentElement.style.setProperty('--one_vw', one_vw + "px");
                document.documentElement.style.setProperty('--one_vh', one_vh + "px");
            }

            updateVhVw();

            window.addEventListener("resize", updateVhVw);

            function rand(min, max) { // min and max included 
                return Math.floor(Math.random() * (max - min + 1) + min);
            };

            // Typewrite
            const typewrite = document.querySelector('.js-typewrite');
            const stings = [
                "MORE THAN MAGIC",
                "МАЛЕНЬКАЯ ТАЛАНТЛИВАЯ МАФИЯ",
                "MAKE TIME MELT",
                "МЫ ТВОРИМ МЕЧТЫ",
                "MASTER THE MIND",
                "МЕЧТАЙ ТАНЦУЙ МЕДИТИРУЙ",
                "MAKE THE MARVEL",
                "МОМЕНТ ТВОЕГО МОГУЩЕСТВА",
                "MOMENTS THAT MATTER",
            ];
        
            // Event chain
            const tw = {
                element: typewrite,
                element_text: typewrite.querySelector(".js-text"),
                element_caret: typewrite.querySelector(".js-caret"),
                events: [],
                stings: [],
                iterator: 0,
                onStringTyped: undefined, 
                imagesCaret: [
                    '<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.6691 0.00012207H0V24.4724H24.6691V0.00012207Z"/></svg>', 
                    '<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3345 24.9444C19.1467 24.9444 24.6691 19.4661 24.6691 12.7083C24.6691 5.95046 19.1467 0.472168 12.3345 0.472168C5.52236 0.472168 0 5.95046 0 12.7083C0 19.4661 5.52236 24.9444 12.3345 24.9444Z"/></svg>'
                ],

                generateEvenst: function () {
                    for(let string of this.stings) {
                        let chars_count = 0;
                        for(let char of string) {
                            chars_count++;
                            this.events.push({fn: "ADD_CHAR", param:char});
                        }
                        this.events.push({fn: "STRING_END", param:null});
                        this.events.push({fn: "DELAY", param:2000});
                        this.events.push({fn: "CHANGE_CARET", param: null});
                        this.events.push({fn: "REMOVE", param:chars_count});
                        this.events.push({fn: "CHANGE_CARET", param: null});
                    }
                    this.events.push({fn: "REGENERATE", param:null});
                    return this;
                },

                loop: function() {
                    this.next().then(()=>{
                        this.iterator++;
                        if(this.iterator < this.events.length) {
                        this.loop();
                        } else {
                            this.iterator = 0;
                            this.loop();
                        }
                    });
                },

                next: async function() {
                    let event = this.events[this.iterator]
                    await this.functions()[event.fn](event.param);
                    
                },

                rand(min, max) { // min and max included 
                    return Math.floor(Math.random() * (max - min + 1) + min);
                },

                functions: function () {
                    return {
                        ADD_CHAR:async (char) => {
                            return new Promise((resolve, reject)=> {
                                setTimeout(() => {
                                    // console.log(char);
                                    this.element_text.innerHTML += char;
                                    resolve();
                                }, this.rand(10, 100));
                            })
                        },
                        STRING_END: async (n) => {
                            return new Promise((resolve, reject)=> {
                                if(typeof this.onStringTyped === "function") {
                                    this.onStringTyped();
                                }

                                resolve();
                            });
                        },
                        REMOVE:async (chars_count) => {
                            return new Promise((resolve, reject)=> {


                                let removeNext = async (chars_count) => {
                                    if(chars_count != 0) {
                                        return new Promise((resolve, reject)=> {
                                            setTimeout(() => {
                                                this.element_text.innerText = this.element_text.innerText.slice(0, -1);
                                                chars_count = chars_count - 1;
                                                removeNext(chars_count);
                                                resolve();
                                            }, 100);
                                        });
                                    }
                                    resolve();
                                }

                                removeNext(chars_count)
                                
                
                            })
                        },
                        DELAY: async (delay_ms) => {
                            return new Promise((resolve, reject)=> {
                                setTimeout(() => { resolve() }, delay_ms);
                            })
                        },
                        REGENERATE: async () => {
                            return new Promise((resolve, reject)=> {
                                resolve();
                            })
                        },
                        CHANGE_CARET: async() => {
                            return new Promise((resolve, reject) => {
                                let img = this.imagesCaret[this.rand(0, this.imagesCaret.length-1)];
                                this.element_caret.innerHTML = img;
                                resolve();
                            })
                        }
                    }
                },
                
            }

            tw.stings = stings;
            console.log(  tw.generateEvenst() );
            tw.loop();



            // Theme switch
            let isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

            const themeBtn = document.querySelector(".js-theme");

            function initTheme() {
                if(isDarkTheme) {
                    setTheme("light");
                    return;
                }

                if(!isDarkTheme) {
                    setTheme("dark");
                    return;
                }
            }

            function switchTheme() {
                if(isDarkTheme) {
                    setTheme("light");
                    return;
                }

                if(!isDarkTheme) {
                    setTheme("dark");
                    return;
                }
            }

            // dark or light
            function setTheme(theme) {
                if(theme == "dark") {
                    isDarkTheme = true;
                    themeBtn.classList.add('active');
                    document.documentElement.setAttribute('data-theme', 'dark');
                    //app.backgroundColor = "";
                    console.log(app);
                    app.renderer.background.color = "#212123";
                    flag.filters = [colorMatrixFilter, alphaFilter, displacementFilter];
                }

                if(theme == "light") {
                    isDarkTheme = false;
                    themeBtn.classList.remove('active');
                    document.documentElement.setAttribute('data-theme', 'light');
                    app.renderer.background.color = "#ffffff";
                    flag.filters = [displacementFilter];
                }
            }
            
            themeBtn.addEventListener('click', function(){
                switchTheme();
            });


            // функция адаптации к экрану размеров
            function gate(max, min, w_max, w_min) {
                let w = window.innerWidth;

                if(w_min > w) {
                    return min;
                }

                if(w_max < w) {
                    return max;
                }

                let w_now = w - w_min;
                let w_delta = w_max - w_min;
                let delta = max - min;
                let w_percent = (100 / w_delta) * w_now;

                return ((delta / 100) * w_percent) + min;

            }


            
            // Create a new application
            const app = new Application();
        
            // Initialize the application
            await app.init({ resizeTo: window, background: '#ffffff'});
        
            // Append the application canvas to the document body
            document.querySelector('.wallpaper').appendChild(app.canvas);
        
            // Load the textures
            await Assets.load([
                'src/img/bg-img.png',
                'src/img/3DlQqNq.png',
            ]);
        
            app.stage.eventMode = 'static';
        
            const container = new Container();
        
            app.stage.addChild(container);
        
            const flag = Sprite.from('src/img/bg-img.png');
        
            container.addChild(flag);
            flag.x =  gate(300, -10, 1440, 375);
            flag.y = gate(0, 100, 1440, 375);
        
            const displacementSprite = Sprite.from('src/img/3DlQqNq.png');
        
            // Make sure the sprite is wrapping.
            displacementSprite.texture.baseTexture.wrapMode = WRAP_MODES.REPEAT;
        
            // Create a displacement filter
            const displacementFilter = new DisplacementFilter({ sprite: displacementSprite, scale: { x: 50, y: 50 } });

            displacementFilter.padding = 100;
            displacementSprite.position = flag.position;

            app.stage.addChild(displacementSprite);

            const colorMatrixFilter = new ColorMatrixFilter();
            colorMatrixFilter.desaturate();

            const alphaFilter = new AlphaFilter();
            alphaFilter.alpha = 0.2;
        
            // Apply the filter
            flag.filters = [displacementFilter];
        
            app.ticker.add(() =>
            {
                // Offset the sprite position to make vFilterCoord update to larger value.
                // Repeat wrapping makes sure there's still pixels on the coordinates.
                displacementSprite.x += Math.cos(displacementSprite.y / 50);
                displacementSprite.y += Math.sin(displacementSprite.x / 60);
                displacementFilter.scale.x += Math.sin(displacementFilter.scale.y / 50);
                displacementFilter.scale.y += Math.cos(displacementFilter.scale.x / 50);
                
            });

            window.addEventListener("resize", () => {
                flag.x = gate(300, -10, 1440, 375);
                flag.y = gate(0, 100, 1440, 375);
            })

            initTheme();
        })();
    /*
    var Mapper = function(width, height, filter) {
        
        var map = [];

        this.zoom = function(px,py) {
            return {
                'x': (px+width/2)*0.2,
                'y': (py+height/2)*0.2
            }
        }  
        
        this.twirl = function(px, py) {
            var x = px-width/2;
            var y = py-height/2;
            var r = Math.sqrt(x*x+y*y);
            var maxr = width/2;
            if (r>maxr) return {
                'x':px,
                'y':py
            }
            var a = Math.atan2(y,x);
            a += 1-r/maxr;
            var dx = Math.cos(a)*r;
            var dy = Math.sin(a)*r;
            return {
                'x': dx+width/2,
                'y': dy+height/2
            }
        }
        
        this.spherize = function(px,py) {
            var x = px-width/2;
            var y = py-height/2;
            var r = Math.sqrt(x*x+y*y);
            var maxr = width/2;
            if (r>maxr) return {'x':px,'y':py}
            var a = Math.atan2(y,x);
            var k = (r/maxr)*(r/maxr)*0.5+0.5;
            var dx = Math.cos(a)*r*k;
            var dy = Math.sin(a)*r*k;
            return {
                'x': dx+width/2,
                'y': dy+height/2
            }
        }

        this.special = function(px, py) {
            // Center
            let x = px-width/2;
            let y = py-height/2;
            

            let rx = (px+width/2) *0.2;
            let ry = (py+height/2) *0.2;

            return {
                'x': rx,
                'y': ry
            }
        }
    
        this.exec = function(bitmap, texture) {
            let height = texture.height;
            let width = texture.width;

            let colorat = function(x,y,channel) {
                return texture.data[(x+y*height)*4+channel];
            }

            for (let j=0; j<height; j++) {
                for (let i=0; i<width; i++) {
                    let u = map[(i+j*height)*2];
                    let v = map[(i+j*height)*2+1];
                    let x = Math.floor(u);
                    let y = Math.floor(v);
                    let kx = u-x;
                    let ky = v-y;
                    for (let c=0; c<4; c++) {
                        bitmap.data[(i+j*height)*4+c] =
                            (colorat(x,y  ,c)*(1-kx) + colorat(x+1,y  ,c)*kx) * (1-ky) +
                            (colorat(x,y+1,c)*(1-kx) + colorat(x+1,y+1,c)*kx) * (ky);
                    }
                }
            }
        };
        
        this.setTranslate = function(translator) {
            if (typeof translator === 'string') translator = this[translator];
            for (var y=0; y<height; y++) {
                for (var x=0; x<width; x++) {
                    var t = translator(x,y);
                    map[(x+y*height)*2+0] = Math.max(Math.min(t.x,width-1),0);
                    map[(x+y*height)*2+1] = Math.max(Math.min(t.y,height-1),0);
                }
            }
        }
        
        this.setTranslate(filter);
    }

    let cnv = {
        canvas: document.querySelector(".js-canvas-input"),
        canvas_out: document.querySelector(".js-canvas-output"),
        ctx: undefined,
        ctx_out: undefined,
        img: undefined,
        scaleFactor: 1,
        x:300,
        y:300,

        patterns: [],
        bitmap: undefined,
        mapper: undefined,
        texture: undefined,

        init: function () {
            this.scaleFactor = this.backingScale();

            this.ctx = this.init_scale(this.canvas);
            this.ctx_out = this.init_scale(this.canvas_out);

            // Maps gen
            for(let i = 0; i < 1; i++) {
                let w = 1000//rand(300, 600);
                let h = 1000//w; //rand(this.canvas.height / 30, this.canvas.height / 4);
                let x = rand(0, this.canvas.width-w);
                let y = rand(0, this.canvas.height- this.canvas.height / 3);

                let mapTypes = ['special'];
                let type = mapTypes[Math.floor(Math.random() * mapTypes.length)];

                this.patterns.push({
                    x: x,
                    y: y,
                    w: w,
                    h: h,
                    type: type,
                    mapper: new Mapper(w, h, type) 
                })
            }

            // Image load
            this.img = new Image();
            this.img.src = 'src/img/bg-img.png';
            this.img.addEventListener('load', () => {
                this.draw();
            });

            return this;
        },
        
        backingScale: function() {
            if ('devicePixelRatio' in window) {
                if (window.devicePixelRatio > 1) {
                    return window.devicePixelRatio;
                }
            }
            return 1;
        },

        init_scale: function(canvas) {
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;

            if (this.scaleFactor > 1) {
                canvas.width = canvas.width * this.scaleFactor;
                canvas.height = canvas.height * this.scaleFactor;
            }

            return canvas.getContext("2d");
    
        },

        drawImage: function(x, y, angle = 0, scale = 1) {
            this.ctx.save();
            this.ctx.translate(x + this.img.width * scale / 2, y + this.img.height * scale / 2);
            this.ctx.rotate(angle);
            this.ctx.translate(- x - this.img.width * scale / 2, - y - this.img.height * scale / 2);
            this.ctx.drawImage(this.img, x, y, this.img.width * scale, this.img.height * scale);
            this.ctx.restore();
        },

        draw: function() {
            // Очистка
            this.ctx.clearRect(0, 0, (this.canvas.width * this.scaleFactor), (this.canvas.height *  this.scaleFactor));
            this.drawImage(this.x, this.y, 0, 2);


            // grid
            if(0) {
                let input = this.ctx;
                let src = this.canvas;
                for(var i=0.5; i<src.width; i+=8) {
                    input.beginPath()
                    input.moveTo(i*2,0);
                    input.lineTo(0,i*2);
                    input.lineWidth = 1;
                    input.strokeStyle = 'hsl(128,100%,'+(src.width-i)/src.width*100+'%)';
                    input.stroke();
            
                    input.beginPath()
                    input.moveTo(i*2-src.width,0);
                    input.lineTo(i*2,src.height);
                    input.lineWidth = 1;
                    input.strokeStyle = 'hsl(192,100%,'+(src.width-i)/src.width*100+'%)';
                    input.stroke();
                    
                    input.beginPath()
                    input.moveTo(0,i);
                    input.lineTo(src.width,i);
                    input.lineWidth = 1;
                    input.strokeStyle = 'hsl(0,70%,'+i/src.width*100+'%)';
                    input.stroke();
                    
                    input.beginPath()
                    input.moveTo(i,0);
                    input.lineTo(i,src.height);
                    input.lineWidth = 1;
                    input.strokeStyle = 'hsl(64,70%,'+i/src.width*100+'%)';
                    input.stroke();
                }
            }

            //this.ctx_out.fillRect(500, 500, 800, 800);

            // Движ
            //this.x -= Math.cos(this.y/100);
            //this.y -= Math.sin(this.x/100);

            // Копируем исхлдное изображения на холст
            let data = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
            this.ctx_out.putImageData(data, 0, 0);
    
            // Расчёт искажений
            for(let pattern of this.patterns) {

                let texture = this.ctx_out.getImageData(pattern.x, pattern.y, pattern.w, pattern.h);
                this.ctx_out.fillRect(pattern.x-1, pattern.y-1, pattern.w+2, pattern.h+2);
                let bitmap = this.ctx_out.getImageData(pattern.x, pattern.y, pattern.w, pattern.h);
                pattern.mapper.exec(bitmap, texture);
                this.ctx_out.putImageData(bitmap, pattern.x, pattern.y, 0, 0, pattern.w, pattern.h);

                pattern.x += Math.cos(pattern.y/50);
                pattern.y += Math.sin(pattern.x/50);


            }

            // Биленейная фильтрация


            requestAnimationFrame(() => {
              this.draw();
            });
        },


    }

    cnv.init();
    */

    /*
    
    var src = document.getElementById('src');
    var dst = document.getElementById('dst');
    var input = src.getContext('2d');
    var output = dst.getContext('2d');
    
    
    // executing
    var bitmap = output.getImageData(0,0,dst.width,dst.height);
    var mapper = new Mapper(bitmap.width, bitmap.height, 'spherize'); 
    mapper.setTranslate('spherize');
    
    var locked = false;
    src.onmousemove = dst.onmousemove = function(e) {
        if (locked) return;
        locked = true;
        var texture = input.getImageData(e.clientX-Math.ceil(bitmap.width/2),e.clientY-Math.ceil(bitmap.height/2),bitmap.width,bitmap.height+1);
        
        dst.style.left = e.clientX-Math.floor(dst.width/2)+'px';
        dst.style.top = e.clientY-(Math.floor(dst.height/2))+'px';

        mapper.exec(bitmap,texture);
        output.putImageData(bitmap,0,0);
        locked = false;
    }

    */
        
    
    
});
